<template>
  <!-- Main sidebar -->
  <div
    class="sidebar sidebar--dark bg-gradient-primary sidebar-main sidebar-fixed sidebar-expand-md"
  >
    <!-- Sidebar mobile toggler -->
    <div class="sidebar-mobile-toggler text-center">
      <a @click="_toggleSidebarMobile" class="sidebar-mobile-main-toggle">
        <i class="icon-arrow-left8"></i>
      </a>
      <h1 class=" font-weight-bolder logo__custom  ">
      {{site_settings.name }}
      <!-- <img src="../assets/global_assets/images/pristine logo.jpeg" alt=""> -->
      </h1>
      <a @click="_toggleSidebarMobile" class="sidebar-mobile-main-toggle">
        <i class="icon-x"></i>
      </a>
    </div>

    <!-- /sidebar mobile toggler -->

    <!-- Sidebar content -->
    <div class="sidebar-content mt-sm-50 pb-0">
     
      <div style="box-shadow: rgb(237, 237, 237) 0px 0px 7px !important;" class="">
        <div style="
        text-align: start !important;
        box-shadow: rgb(237 237 237) 0px 0px 7px !important;
        " class="navbar-brand d-sm-flex d-none align-items-center w-100 mb-2 justify-content-center ">
        <a href="/" class="d-inline-block">
            <h1 class=" font-weight-bolder logo__custom  ">
            {{site_settings.name }}

            </h1>
            <!-- <img src="../assets/global_assets/images/pristine logo.jpeg"  alt=""> -->

        </a>
        </div>
        <!-- <div v-if="userType != userTypes.writer" class="d-flex align-items-center  justify-content-center  text-center">
            <router-link style="width: 90%!important;" :to="{ name: 'PlaceOrder' }"  class="btn--custom--aside mb-2 rounded " type="button" id="of-onbording">Order now</router-link>
        </div> -->
      </div>

      <!-- Main navigation -->
      <div class="">
        <ul class="navbar-nav">
          <!-- Sidebar - Brand -->
          <!-- <a class="sidebar-brand d-flex align-items-center" href="/orders" >
          </a>   -->
          <li class="nav-item-header text-left">
            <div class="text-uppercase font-size-xs color-faded line-height-xs">Main</div>
            <!-- <i class="icon-menu" title="Main"></i> -->
          </li>

          <li
          class="nav--item">
              <router-link :to="{ name: 'Profile' }"
              style="position:relative!important;  width:100%!important;"
                          
              class="nav--link  ">
                  <!-- <i class="icon-users"></i> -->  
                  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>            
                  <!-- <v-icon name="user" flip="horizontal" inverse="false" color="white"/> -->

                  <span class="nav-link-name">{{ user.first_name || "" }} {{user.last_name || ""}}</span>
                  <div
                  style="
                    width: 90px;
                    position: absolute!important;
                    float: right;
                    right: 0;
                    top: 10px;
                    " 
                    class="">
                    <p   
                    style=" 
                    width: 24px;
                    text-align: right;
                    float: right;
                    "               
                      class=" d-flex align-items-center justify-content-center mt-2  badge-pill mr-3 "
                      >
                      <svg viewBox="64 64 896 896" focusable="false" fill="#fff"  width="1em" height="1em" data-icon="right" aria-hidden="true"><path fill="#fff" d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path></svg>
                      </p>
                  </div>
              
               
              
              </router-link>
              
          </li>
          
          <li
            v-for="(item, index) in baseMenuItems"
            :key="index + 'bmi'"
            class="nav--item"
          >
            <router-link :to="{ name: item.routeName }"  class="nav--link w-100 ">
              <!-- <i :class="item.icon"></i> -->
              <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trello"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><rect x="7" y="7" width="3" height="9"></rect><rect x="14" y="7" width="3" height="5"></rect></svg>
              <span>{{ item.text }}</span>
            </router-link>
          </li>

          <li
            class="nav--item py-0"
            v-if="userType != userTypes.writer  "
          >
            <router-link :to="{ name: 'NewEmail' }" class="nav--link w-100" >
                <!-- <i class="icon-paperplane"></i> -->
                <!-- <v-icon scale="2" name="inbox" flip="horizontal" inverse="false" color="white"/> -->
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" viewBox="0 0 576 512"><path d="M96 0C78.3 0 64 14.3 64 32V224h96V192c0-35.3 28.7-64 64-64H448V32c0-17.7-14.3-32-32-32H96zM224 160c-17.7 0-32 14.3-32 32v32h96c35.3 0 64 28.7 64 64V416H544c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32H224zm240 64h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H464c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zM32 256c-17.7 0-32 14.3-32 32v13L155.1 415.9c1.4 1 3.1 1.6 4.9 1.6s3.5-.6 4.9-1.6L320 301V288c0-17.7-14.3-32-32-32H32zm288 84.8L184 441.6c-6.9 5.1-15.3 7.9-24 7.9s-17-2.8-24-7.9L0 340.8V480c0 17.7 14.3 32 32 32H288c17.7 0 32-14.3 32-32V340.8z"/></svg>
                <span class="text-left">Bulk Messages</span>
            </router-link>
          </li>
          <li
            class="nav--item py-0"
            v-if="userType != userTypes.writer  "
          >
            <router-link :to="{ name: 'NewNot' }" class="nav--link w-100" >
                <!-- <i class="icon-paperplane"></i> -->
                <!-- <v-icon scale="2" name="inbox" flip="horizontal" inverse="false" color="white"/> -->
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" viewBox="0 0 576 512"><path d="M96 0C78.3 0 64 14.3 64 32V224h96V192c0-35.3 28.7-64 64-64H448V32c0-17.7-14.3-32-32-32H96zM224 160c-17.7 0-32 14.3-32 32v32h96c35.3 0 64 28.7 64 64V416H544c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32H224zm240 64h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H464c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zM32 256c-17.7 0-32 14.3-32 32v13L155.1 415.9c1.4 1 3.1 1.6 4.9 1.6s3.5-.6 4.9-1.6L320 301V288c0-17.7-14.3-32-32-32H32zm288 84.8L184 441.6c-6.9 5.1-15.3 7.9-24 7.9s-17-2.8-24-7.9L0 340.8V480c0 17.7 14.3 32 32 32H288c17.7 0 32-14.3 32-32V340.8z"/></svg> 
                <span class="text-left">Bulk News</span>
            </router-link>
          </li>

          <li
            class="nav--item py-0"
            v-show="userType && userType == userTypes.admin" 
          >
            <router-link :to="{ name: 'AllNews' }" class="nav--link w-100" >
                <!-- <i class="icon-paperplane"></i> -->
                <!-- <v-icon scale="2" name="inbox" flip="horizontal" inverse="false" color="white"/> -->
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="28" height="28" viewBox="0 0 256 256" xml:space="preserve">
                <defs>
                </defs>
                <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                  <path d="M 84.563 16.063 h -4.426 v -2.891 c 0 -2.634 -2.143 -4.776 -4.775 -4.776 H 71.53 v -3.62 C 71.53 2.143 69.388 0 66.754 0 H 5.437 C 2.803 0 0.661 2.143 0.661 4.776 v 73.637 C 0.661 84.802 5.859 90 12.248 90 h 67.187 c 5.461 0 9.904 -5.495 9.904 -12.249 V 20.84 C 89.339 18.206 87.196 16.063 84.563 16.063 z M 12.248 88 c -5.287 0 -9.587 -4.301 -9.587 -9.587 V 4.776 C 2.661 3.245 3.906 2 5.437 2 h 61.317 c 1.531 0 2.776 1.245 2.776 2.776 v 72.975 c 0 4.282 1.786 8.059 4.485 10.249 H 12.248 z M 87.339 77.751 c 0 5.651 -3.546 10.249 -7.904 10.249 s -7.904 -4.598 -7.904 -10.249 V 10.396 h 3.832 c 1.53 0 2.775 1.246 2.775 2.776 v 65.307 c 0 0.553 0.447 1 1 1 s 1 -0.447 1 -1 V 18.063 h 4.426 c 1.53 0 2.775 1.246 2.775 2.776 V 77.751 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #fff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  <path d="M 35.662 61.153 h -23.69 c -1.554 0 -2.818 -1.265 -2.818 -2.818 V 34.368 c 0 -1.554 1.264 -2.818 2.818 -2.818 h 23.69 c 1.554 0 2.818 1.264 2.818 2.818 v 23.967 C 38.48 59.889 37.216 61.153 35.662 61.153 z M 11.972 33.55 c -0.451 0 -0.818 0.367 -0.818 0.818 v 23.967 c 0 0.451 0.367 0.818 0.818 0.818 h 23.69 c 0.451 0 0.818 -0.367 0.818 -0.818 V 34.368 c 0 -0.451 -0.367 -0.818 -0.818 -0.818 H 11.972 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #fff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  <path d="M 61.756 33.55 H 46.347 c -0.552 0 -1 -0.448 -1 -1 s 0.448 -1 1 -1 h 15.409 c 0.553 0 1 0.448 1 1 S 62.309 33.55 61.756 33.55 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #fff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  <path d="M 61.756 42.751 H 46.347 c -0.552 0 -1 -0.448 -1 -1 s 0.448 -1 1 -1 h 15.409 c 0.553 0 1 0.448 1 1 S 62.309 42.751 61.756 42.751 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #fff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  <path d="M 61.756 51.952 H 46.347 c -0.552 0 -1 -0.447 -1 -1 s 0.448 -1 1 -1 h 15.409 c 0.553 0 1 0.447 1 1 S 62.309 51.952 61.756 51.952 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #fff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  <path d="M 61.756 61.153 H 46.347 c -0.552 0 -1 -0.447 -1 -1 s 0.448 -1 1 -1 h 15.409 c 0.553 0 1 0.447 1 1 S 62.309 61.153 61.756 61.153 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #fff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  <path d="M 61.756 70.354 H 10.154 c -0.552 0 -1 -0.447 -1 -1 s 0.448 -1 1 -1 h 51.602 c 0.553 0 1 0.447 1 1 S 62.309 70.354 61.756 70.354 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #fff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  <path d="M 61.756 79.556 H 10.154 c -0.552 0 -1 -0.447 -1 -1 s 0.448 -1 1 -1 h 51.602 c 0.553 0 1 0.447 1 1 S 62.309 79.556 61.756 79.556 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #fff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  <path d="M 19.513 24.604 c -0.311 0 -0.61 -0.145 -0.803 -0.404 l -7.557 -10.176 v 9.58 c 0 0.552 -0.448 1 -1 1 s -1 -0.448 -1 -1 V 11 c 0 -0.431 0.276 -0.814 0.686 -0.949 c 0.407 -0.134 0.859 0.007 1.117 0.353 l 7.557 10.176 V 11 c 0 -0.552 0.448 -1 1 -1 s 1 0.448 1 1 v 12.604 c 0 0.431 -0.276 0.814 -0.686 0.949 C 19.724 24.587 19.618 24.604 19.513 24.604 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #fff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  <path d="M 31.344 18.302 c 0.552 0 1 -0.448 1 -1 s -0.448 -1 -1 -1 h -5.357 V 12 h 5.357 c 0.552 0 1 -0.448 1 -1 s -0.448 -1 -1 -1 h -6.357 c -0.552 0 -1 0.448 -1 1 v 12.604 c 0 0.552 0.448 1 1 1 h 6.357 c 0.552 0 1 -0.448 1 -1 s -0.448 -1 -1 -1 h -5.357 v -4.302 H 31.344 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #fff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  <path d="M 38.981 24.604 c -0.015 0 -0.03 0 -0.045 -0.001 c -0.448 -0.02 -0.828 -0.335 -0.929 -0.772 l -2.939 -12.604 c -0.125 -0.538 0.208 -1.076 0.747 -1.201 c 0.535 -0.125 1.075 0.208 1.201 0.747 l 2.131 9.136 l 3.041 -9.222 c 0.135 -0.41 0.518 -0.687 0.95 -0.687 l 0 0 c 0.432 0 0.814 0.277 0.95 0.687 l 3.04 9.221 l 2.131 -9.135 c 0.125 -0.538 0.658 -0.873 1.201 -0.747 c 0.537 0.125 0.872 0.663 0.746 1.201 l -2.939 12.604 c -0.102 0.437 -0.481 0.752 -0.929 0.772 c -0.477 0.009 -0.854 -0.26 -0.995 -0.686 l -3.204 -9.723 l -3.206 9.723 C 39.794 24.329 39.411 24.604 38.981 24.604 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #fff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  <path d="M 58.605 24.604 h -4.702 c -0.553 0 -1 -0.448 -1 -1 s 0.447 -1 1 -1 h 4.702 c 1.186 0 2.15 -0.965 2.15 -2.151 s -0.965 -2.151 -2.15 -2.151 h -1.552 c -2.288 0 -4.15 -1.862 -4.15 -4.151 S 54.766 10 57.054 10 h 3.632 c 0.553 0 1 0.448 1 1 s -0.447 1 -1 1 h -3.632 c -1.186 0 -2.15 0.965 -2.15 2.151 s 0.965 2.151 2.15 2.151 h 1.552 c 2.288 0 4.15 1.862 4.15 4.151 S 60.894 24.604 58.605 24.604 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #fff; fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                </g>
                </svg>
                <span class="text-left">All News</span>
            </router-link>
          </li>

          <li
            class="nav--item py-0"
            v-show="(userType == userTypes.admin) "
          >
            <router-link :to="{ name: 'SystemPayments' }" class="nav--link w-100" >
                <v-icon scale="1.6" name="credit-card" flip="horizontal" inverse="false" color="white"/>

                <!-- <i class="credit-card"></i> -->
                <span class="text-left">System Payments</span>
            </router-link>
          </li>

          <li v-if="userType == userTypes.admin" class="nav-item-header text-left">
            <div class="text-uppercase font-size-xs color-faded line-height-xs">Classes</div>
          </li>

          <li
          v-show="userType && userType == userTypes.admin"
          v-for="(item, index) in classHelpStats"
          :key="index + 'om'"
          class="nav--item">
              <router-link
              @click="_getClassHelpStats"
              :to="{ name: 'ClassListing', params: { 
                status: item.value
                
                } }"
              style="position:relative!important; width:100%!important; "
                          
              class="nav--link  ">
                  <!-- <i class="icon-pencil"></i> -->
                  <v-icon scale="1.6" name="edit" flip="horizontal" inverse="false" color="white"/>
                  <span class="nav-link-name">{{ item.name }}</span>
                  <div
                  style="
                    width:  90px;
                    position: absolute!important; 
                    float: right;
                    right: 0;
                    top: 5px;
                    " 
                    class="">
                    <p   
                    style=" 
                    width: 24px;
                    text-align: right;
                    float: right;
                    "               
                      class="badge d-flex align-items-center justify-content-center mt-2  badge-pill mr-3 "
                      :class="`bg-${item.color || 'dark'}`"
                      >{{ item.no || 0 }}</p>
                  </div>
              
               
              
              </router-link>
              
          </li>
          

          <li class="nav-item-header text-left">
            <div class="text-uppercase font-size-xs color-faded line-height-xs">Orders</div>
            <!-- <i class="icon-menu" title="Main"></i> -->
          </li>

          <li
          class="nav--item">
              <router-link
              :to="{ name: 'Bids' }"
              style="position:relative!important;  width:100%!important;"
                          
              class="nav--link  ">
                 
                  <v-icon scale="1.6" name="cart-plus" flip="horizontal" inverse="false" color="white"/>

                  <span class="nav-link-name">Bids</span>
                  <div
                  style="
                    width: 90px;
                    position: absolute!important;
                    float: right;
                    right: 0;
                    top: 5px;
                    " 
                    class="">
                    <p   
                    style=" 
                    width: 24px;
                    text-align: right;
                    float: right;
                    "               
                      class="badge bg-info d-flex align-items-center justify-content-center mt-2  badge-pill mr-3 "
                      >{{ bidsCount || 0}}</p>
                  </div>
              
               
              
              </router-link>
              
          </li>

          <li
          v-for="(item, index) in sidebarMenu"
          :key="index + 'om'"
          class="nav--item">
              <router-link
              @click="getSidebarMenu"
              :to="{ name: 'OrderListing', params: { 
                status: item.value,
                canbid: item.can_bid ? 'biding' : 'b',
                 } }"
              style="position:relative!important; width:100%!important; "
                          
              class="nav--link  ">
                  <!-- <i class="icon-pencil"></i> -->
                  <v-icon scale="1.6" name="edit" flip="horizontal" inverse="false" color="white"/>
                  <span class="nav-link-name">{{ item.name }}</span>
                  <div
                  style="
                    width: 90px;
                    position: absolute!important;
                    float: right;
                    right: 0;
                    top: 5px;
                    " 
                    class="">
                    <p   
                    style=" 
                    width: 24px;
                    text-align: right;
                    float: right;
                    "               
                      class="badge d-flex align-items-center justify-content-center mt-2  badge-pill mr-3 "
                      :class="`bg-${item.color || 'dark'}`"
                      >{{ item.no || 0 }}</p>
                  </div>
              
               
              
              </router-link>
              
          </li>

          <li class="nav-item-header text-left">
            <div class="text-uppercase font-size-xs color-faded line-height-xs">Menu</div>
            <!-- <i class="icon-menu" title="Main"></i> -->
          </li>

          <span
              v-show="userType == userTypes.admin && user.id != '5986'"
            >
          <li
          v-for="(item, index) in adminMenu"
                :key="index + 'bmi'"
            class="nav--item"
          >
            <router-link :to="{ name: item.routeName }" class="nav--link">
              <!-- <i :class="item.icon"></i> -->
              <v-icon scale="1.6" :name="item.icon" flip="horizontal" inverse="false" color="white"/>

              <span>{{ item.text }}</span>
            </router-link>
          </li>
          </span>

          <span
            v-show="userType && userType == userTypes.client"
            >
          <li
          v-for="(item, index) in clientMenu"
                :key="index + 'bmi'"
            class="nav--item"
          >
            <router-link :to="{ name: item.routeName }" class="nav--link">
              <!-- <i :class="item.icon"></i> -->
              <v-icon scale="1.6" :name="item.icon" flip="horizontal" inverse="false" color="white"/>

              <!-- <v-icon scale="2" name="edit" flip="horizontal" inverse="false" color="white"/> -->

              <span>{{ item.text }}</span>
            </router-link>
          </li>
          </span>

          <span
          v-show="userType && userType == userTypes.editor"
            >
          <li
          v-for="(item, index) in editorMenu"
                :key="index + 'bmi'"
            class="nav--item"
          >
            <router-link :to="{ name: item.routeName }" class="nav--link">
              <!-- <i :class="item.icon"></i> -->
              <v-icon scale="1.6" :name="item.icon" flip="horizontal" inverse="false" color="white"/>

              <span>{{ item.text }}</span>
            </router-link>
          </li>
          </span>

          <span
          v-show="userType && userType == userTypes.writer"
            >
          <li
          v-for="(item, index) in writerMenu"
                :key="index + 'bmi'"
            class="nav--item"
          >
            <router-link :to="{ name: item.routeName }" class="nav--link">
              <!-- <i :class="item.icon"></i> -->
              <v-icon scale="1.6" :name="item.icon" flip="horizontal" inverse="false" color="white"/>

              <span>{{ item.text }}</span>
            </router-link>
          </li>
          </span>
          <span
          v-show="userType == userTypes.admin || userType == userTypes.client"
            >
          <!-- <li
          v-for="(item, index) in WriterLevels"
                :key="index + 'bmi'"
            class="nav--item"
          >
            <router-link :to="{ name: item.routeName }" class="nav--link">
              <i :class="item.icon"></i>
              <span>{{ item.text }}</span>
            </router-link>
          </li> -->
          </span>
          
                
        </ul>
      </div>
      <!-- /main navigation -->
    </div>
    <!-- /sidebar content -->
  </div>
  <!-- /main sidebar -->
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import {
  actionMenuItems,
  baseMenuItems,
  writerMenu,
  clientMenu,
  editorMenu,
  adminMenu,
  setupsMenu,
  
} from "./sidebar.model";

export default {
  name: "Sidebar",

  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["userType", "userTypes"]),
    ...mapState("sidebar", ["showSidebar", "sidebarMenu", "bidsCount"]),
    ...mapState("setups", ["site_settings"]),
    ...mapState("payment", [
      "balanceTotals",

    ]),

    ...mapState("orders", ["classHelpStats"]),


  },
  data() {
    return {
      actionMenuItems: actionMenuItems,
      baseMenuItems: baseMenuItems,
      adminMenu: adminMenu,
      clientMenu: clientMenu,
      writerMenu: writerMenu,
      editorMenu: editorMenu,
      setupsMenu: setupsMenu, 
      toggle_setups: false,
    };
  },
 
  async mounted() {
    await  this.getSidebarMenu();
    await  this.getSiteSettings();
    await  this._getClassHelpStats();
    await  this._getBalancePayments().catch((err) =>
      console.log("error fetching payments", err)
    );

  },
  methods: {
    ...mapActions("auth", ["doLogout"]),
    ...mapActions("sidebar", ["getSidebarMenu"]),
    ...mapMutations("sidebar", ["_toggleSidebarMobile"]),
    ...mapActions("setups", ["getSiteSettings"]),
    ...mapActions("payment", ["_getBalancePayments"]),
    ...mapActions("orders", ["_getClassHelpStats"]),


  },
};
</script>


<style lang="scss" scoped>
.sidebar-xs {
  width: 100% !important;
}
.nav-item {
  max-height: 40px !important;
  min-height: 40px !important;
  height: 40px !important;
}
.nav-link {
  padding: 8px 10px !important;
}
.sidebar {
  .nav-link {
    padding: 0.5rem 0.8rem;
  }
}
.modal-backdrop {
  z-index: 1 !important;
}

// .sidebar-xs .sidebar-main .nav-sidebar > .nav-item > .nav-link {
//   padding-left: 0;
//   padding-right: 0;
//   max-height: 40px !important;
// }

.is-conditional-menu * {
  transition: 0.3s all ease-in-out;
}

.nav--item > .nav--link.active {
  border-radius: 0px 0px 0px 0px;
  margin-right: 0px;
  background: rgba(184, 193, 198, 0.49) !important;
  transition: 0.3s ease-in-out;
  border-right: 6px solid #E6EEF2 !important;
}

.nav--item .nav--link:hover {
  background: rgba(184, 193, 198, 0.49) !important;

}

.nav-link.active.just-active {
  max-height: 34px !important;
}
.sidebar-xs {
  .nav-item-header {
    display: none;
  }
}

.nav-sidebar .nav-link i {
  margin-right: 1.25rem;
  margin-top: 0rem !important;
  margin-bottom: 0.12502rem;
  top: 0;
}

.sidebar-xs .sidebar-main {
  // width: 2.8rem;
  width: 16.875rem;

  a.nav-link.bg-primary.py-1.rounded {
    border-radius: 0px 8px 8px 0px !important;
    max-height: 20px;
    margin-right: 5px;
  }
}
// .sidebar-xs .sidebar-main:hover {
//   width: 16.875rem;
//   -webkit-animation: slideInOut 0.2s;
//   -moz-animation: slideInOut 0.2s;
//   -o-animation: slideInOut 0.2s;
//   animation: slideInOut 0.2s;
// }

@keyframes slideInOut {
  0% {
    width: 2.8rem;
  }
  100% {
    width: 16.875rem;
  }
}

@-o-keyframes slideInOut {
  0% {
    width: 2.8rem;
  }
  100% {
    width: 16.875rem;
  }
}
@-moz-keyframes slideInOut {
  0% {
    width: 2.8rem;
  }
  100% {
    width: 16.875rem;
  }
}
@-webkit-keyframes slideInOut {
  0% {
    width: 2.8rem;
  }
  100% {
    width: 16.875rem;
  }
}

.nav-link.bg-primary {
  border-radius: 0px 8px 8px 0px;
}

.nav.nav-group-sub.d-block .nav-item .nav-link {
  padding-left: 22px !important;
}
.nav-item-submenu.nav-item-open a {
  border-radius: 0 8px 8px 0px !important;
}
</style>